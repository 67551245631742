import { useState } from 'react'
import { useAuth } from '../context/authContext'
import { Link, useNavigate } from 'react-router-dom';
import { Alert } from './Alert';

export function Login() {
    const { guardarEnFirebase, verificarUsuarioEnFirebase, GuardarEnLocal } = useAuth();


    const [user, setUser] = useState({
        email: '',
        password: '',
    });

    const { login, loginWithGoogle, resetPassword, Estado } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState();

    const handleChange = ({ target: { name, value } }) => {

        setUser({ ...user, [name]: value });

    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {

            const userCredential = await login(user.email, user.password)
            const uid = userCredential.user.uid;
            await GuardarEnLocal(uid);
            await Estado(uid);
            localStorage.setItem('Puntos', 0);
            navigate('/Comprobacion')

        } catch (error) {


            setError(error.message);
        }

    }

    const handleGoogleSignin = async () => {
        try {
            const userCredential = await loginWithGoogle();
            const uid = userCredential.user.uid;
            const nombre = userCredential.user.displayName;
            const usuarioExistente = await verificarUsuarioEnFirebase(uid);
            localStorage.setItem('nombre', JSON.stringify(nombre));
            localStorage.setItem('Puntos', 0);


            if (usuarioExistente) {
                //console.log('Usuario en base de datos', usuarioExistente)
                await GuardarEnLocal(uid);
            } else {
                // console.log('Usuario NO en base de datos');
                // Guardar el usuario en la base de datos
                await guardarEnFirebase(nombre, uid);
                await GuardarEnLocal(uid);

            }
            await Estado(uid);

            navigate('/Comprobacion');
        } catch (error) {
            setError(error.message);
        }

    };

    const handleResetPassword = async () => {
        if (!user.email) return setError('Por favor, ingresa tu email');

        try {
            await resetPassword(user.email);
            setError('Te hemos enviado un e-mail con un link para resetear tu password');

        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="w-full max-w-xs m-auto">
            {error && <Alert message={error} />}

            <form onSubmit={handleSubmit} className='bg-teal-200 shadow-md rounded px-8 pt-6 pb-10 mb-4 '>

                <div className='mb-4'>
                    <img className='text-xl mb-4' src={"https://trottingexperience.com/wp-content/uploads/2023/12/Logo.png"} />
                </div>

                <label htmlFor="email" className='block text-gray-700 text-sm font-bold mb-2'>Email</label>
                <input type="email"
                    name="email"
                    placeholder="tuemail@gmail.com"
                    className='shadow appearance-none border rounded w-full py-2 py-3 text-gray-700 leading-tight
                    focus: outline-none focus:shadow-outline'
                    onChange={handleChange} />



                <div className='mb-4'>

                    <label htmlFor="password">Password</label>
                    <input type="password"
                        name="password"
                        id="password"
                        onChange={handleChange}
                        placeholder='******'
                        className='shadow appearance-none border rounded w-full py-2 py-3 text-gray-700 leading-tight
                    focus: outline-none focus:shadow-outline'
                    />

                </div>

                <div className='flex items-center justify-between'>

                    <button className='bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded 
            focus:outline-none focus:shadow-outline'> Login </button>

                    <a href='#!' className='inline-block align-baseline font-bold text-sm 
            text-blue-500 hover:text-blue-800'
                        onClick={handleResetPassword}
                    > ¿Olvidaste tu contraseña?</a>

                </div>

            </form>

            <p className='my-4 text-sm flex justify-between px-3'> No tienes una cuenta? <Link to='/register'> Regístrate </Link> </p>

            <button onClick={handleGoogleSignin} className='bg-slate-50 hover:bg-slate-200 text-black shadow-md
        rounded border-2 border-gray-300 py-2 px-4 w-full'> Login with Google </button>

            <p className='my-4 text-sm flex justify-between px-3'>
                Al usar nuestra app aceptas la política privacidad
                <a href='https://trottingexperience.com/privacidad' target='_blank' rel='noopener noreferrer'>
                    Privacidad
                </a>
            </p>
        </div>
    )

}

export default Login;
