/* global Accelerometer */

import { useAuth, db } from '../context/authContext';
import { useState, useEffect, useRef } from 'react';
import { collection, query, onSnapshot } from 'firebase/firestore';

import Reloj from './Reloj.js';
import Calorias from './Calorias.js';
import Distancia from './Distancia.js';

import AccelerometerApp from './AccelerometerApp.js';
import { useNavigate } from 'react-router-dom';



export function Home() {

   const { logout, loading, emoji, emoji1, emoji2, emoji3, emoji4, emoji5, emoji6, emoji7, emoji8, emoji9, listaUsuarios, EstadoLogout, addInfoGym, addRanking, cerrarClase, resetRanking } = useAuth();

   const navigate = useNavigate();

   

   const handleLogout = async () => {
      try {
         await addInfoGym('');
         localStorage.removeItem('gimnasio');
         await EstadoLogout();
         await logout();

      } catch (error) {
         console.error(error);
      }
   };
   const [mensajeError, setMensajeError] = useState('');

   const handleCerrar = async () => {
      try {
         // Obtener datos de Firebase y del LocalStorage
         const datosObtenidos = await listaUsuarios();
         const gimnasioGuardadoString = localStorage.getItem('gimnasio');
         const gimnasioGuardado = JSON.parse(gimnasioGuardadoString);
         // Filtrar personas activas en el gimnasio
         const personasActivasEnGimnasio = datosObtenidos
            .filter(persona => persona.activo && persona.nombreGym === gimnasioGuardado)
            .map(persona => ({ nombre: persona.nombre, mensaje: persona.mensajes, id: persona.uidd || [] }));
         await cerrarClase(personasActivasEnGimnasio);
         setMensajeError('La clase ha finalizado, por favor cierre la clase, Gracias!');

      } catch (error) {
         console.error(error);
      }
   };

   const handleCero = async () => {
      try {
         await resetRanking();
         
      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji = async () => {
      try {
         // Actualiza el emoji en la base de datos
         await emoji();
         await setEmojis('❤️');
         // Espera 3 segundos antes de limpiar el emoji
         setTimeout(async () => {
            await setEmojis(''); // Limpia el emoji estableciendo emojis a una cadena vacía
         }, 5000);
         // Tu lógica para manejar los cambios en las personas aquí
         setpuntosRanking(prevRanking => prevRanking + 1);
         localStorage.setItem('Puntos', puntosRanking);
      } catch (error) {
         console.error(error);
      }
   };


   const handleEmoji1 = async () => {
      try {
         await emoji1();
         await setEmojis('👍');
         setTimeout(async () => {
            await setEmojis(''); // Limpia el emoji estableciendo emojis a una cadena vacía
         }, 5000);
         // Tu lógica para manejar los cambios en las personas aquí
         setpuntosRanking(prevRanking => prevRanking + 1);
         localStorage.setItem('Puntos', puntosRanking);

      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji2 = async () => {
      try {
         await emoji2();
         await setEmojis('💪');
         setTimeout(async () => {
            await setEmojis(''); // Limpia el emoji estableciendo emojis a una cadena vacía
         }, 5000);
         // Tu lógica para manejar los cambios en las personas aquí
         setpuntosRanking(prevRanking => prevRanking + 1);
         localStorage.setItem('Puntos', puntosRanking);

      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji3 = async () => {
      try {
         await emoji3();
         await setEmojis('😅');
         setTimeout(async () => {
            await setEmojis(''); // Limpia el emoji estableciendo emojis a una cadena vacía
         }, 5000);
         // Tu lógica para manejar los cambios en las personas aquí
         setpuntosRanking(prevRanking => prevRanking + 1);
         localStorage.setItem('Puntos', puntosRanking);

      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji4 = async () => {
      try {
         await emoji4();
         await setEmojis('👊');
         setTimeout(async () => {
            await setEmojis(''); // Limpia el emoji estableciendo emojis a una cadena vacía
         }, 5000);
         // Tu lógica para manejar los cambios en las personas aquí
         setpuntosRanking(prevRanking => prevRanking + 1);
         localStorage.setItem('Puntos', puntosRanking);

      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji5 = async () => {
      try {
         await emoji5();
         await setEmojis('💦');
         setTimeout(async () => {
            await setEmojis(''); // Limpia el emoji estableciendo emojis a una cadena vacía
         }, 5000);
         // Tu lógica para manejar los cambios en las personas aquí
         setpuntosRanking(prevRanking => prevRanking + 1);
         localStorage.setItem('Puntos', puntosRanking);

      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji6 = async () => {
      try {
         await emoji6();
         await setEmojis('🐘');
         setTimeout(async () => {
            await setEmojis(''); // Limpia el emoji estableciendo emojis a una cadena vacía
         }, 5000);
         // Tu lógica para manejar los cambios en las personas aquí
         setpuntosRanking(prevRanking => prevRanking + 1);
         localStorage.setItem('Puntos', puntosRanking);

      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji7 = async () => {
      try {
         await emoji7();
         await setEmojis('🏆');
         setTimeout(async () => {
            await setEmojis(''); // Limpia el emoji estableciendo emojis a una cadena vacía
         }, 5000);
         // Tu lógica para manejar los cambios en las personas aquí
         setpuntosRanking(prevRanking => prevRanking + 1);
         localStorage.setItem('Puntos', puntosRanking);

      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji8 = async () => {
      try {
         await emoji8();
         await setEmojis('👏');
         setTimeout(async () => {
            await setEmojis(''); // Limpia el emoji estableciendo emojis a una cadena vacía
         }, 5000);
         // Tu lógica para manejar los cambios en las personas aquí
         setpuntosRanking(prevRanking => prevRanking + 1);
         localStorage.setItem('Puntos', puntosRanking);

      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji9 = async () => {
      try {
         await emoji9();
         await setEmojis('🥵');
         setTimeout(async () => {
            await setEmojis(''); // Limpia el emoji estableciendo emojis a una cadena vacía
         }, 5000);
         // Tu lógica para manejar los cambios en las personas aquí
         setpuntosRanking(prevRanking => prevRanking + 1);
         localStorage.setItem('Puntos', puntosRanking);

      } catch (error) {
         console.error(error);
      }
   };

   const handleClase = async () => {
      try {
         await addInfoGym('37');
         await addRanking(puntosRanking)
         localStorage.removeItem('gimnasio');
         navigate('/Comprobacion')


      } catch (error) {
         console.error(error);
      }
   };

   const handleRanking = async (puntosRanking) => {
      try {
         await addRanking(puntosRanking)
         navigate('/Ranking')


      } catch (error) {
         console.error(error);
      }
   };


   const [personas, setPersonas] = useState([]);
   const [personasUser, setpersonasUser] = useState("");
   const [emojis, setEmojis] = useState('');
   const [puntosRanking, setpuntosRanking] = useState(0); // Agregamos el estado local para mantener el ranking

   useEffect(() => {
      const q = query(collection(db, 'usuarios'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
         // Manejar los cambios en los datos aquí
         handlePersonas(snapshot);
      });

      return () => unsubscribe();
   }, []);

   const handlePersonas = async () => {
      try {
         
         const gimnasioGuardadoString = localStorage.getItem('gimnasio');
         const gimnasioGuardado = JSON.parse(gimnasioGuardadoString);

         const userSinGymString = localStorage.getItem('usuario');
         const userSinGym = JSON.parse(userSinGymString);

         const nombreString = localStorage.getItem('nombre');
         const nombre = JSON.parse(nombreString);

         const datosObtenidos = await listaUsuarios();

         const personasActivasEnGimnasio = datosObtenidos
            .filter(persona => persona.activo && persona.nombreGym === gimnasioGuardado && persona.uidd != userSinGym)
            .map(persona => ({ nombre: persona.nombre, mensaje: persona.mensajes, puntos: persona.puntos || [] }));

            const personasTodo = datosObtenidos
            .filter(persona => persona.activo && persona.nombreGym === gimnasioGuardado)
            .map(persona => ({ nombre: persona.nombre, mensaje: persona.mensajes, puntos: persona.puntos || [] }));

         setPersonas(personasActivasEnGimnasio);
         setpersonasUser(nombre);

         if (personasTodo.length !== 0) {
            localStorage.setItem('personasActivas', JSON.stringify(personasActivasEnGimnasio));
         } else {
            await EstadoLogout();
            await logout();
         }
      } catch (error) {
         console.error(error);
      }
   };

   if (loading) return <h1> Loading...</h1>

   return (


      <div className='w-full text-black bg-cyan-300'>
         <div className>
            <h1 className='text-xl'>
               <img
                  src="https://trottingexperience.com/wp-content/uploads/2023/08/Logo-dark-11.png"
                  className=" top left-0 w-160 h-160 object-cover rounded-md mx-auto my-8"
               />
               <p className="text-xl text-center font-bold">You'll Never Train Alone!</p>
               <p className="text-xl text-center font-bold">Código TROTTING 1234</p>


            </h1>

            <div className="flex flex-wrap justify-center">
               <div className="m-4 text-center">
                  <Reloj />
                  <h1>Duración</h1>
               </div>

               <div className="m-4 text-center">
                  <Distancia />
                  <h1>Recorrido</h1>
               </div>

               <div className="m-4 text-center">
                  <Calorias />
                  <h1>Calorías</h1>
               </div>
            </div>
            <div className="m-4 text-center">
               {mensajeError && <p style={{ color: 'red' }}>{mensajeError}</p>}
            </div>

            <ul className='grid grid-cols-4 gap-2 text-xl justify-centerr'>
               {personas.map((persona, index) => {
                  const colors = {
                     0: 'bg-red-500',
                     1: 'bg-blue-500',
                     2: 'bg-green-500',
                     3: 'bg-yellow-500',
                     4: 'bg-pink-500',
                     5: 'bg-purple-500',
                     6: 'bg-indigo-500',
                     7: 'bg-orange-500',
                     8: 'bg-teal-500',
                     9: 'bg-blue-300',
                     10: 'bg-yellow-200',
                     11: 'bg-cyan-500',
                     12: 'bg-lime-500',
                     13: 'bg-amber-500',
                  };
                  const colorClass = colors[index % 14];
                  return (
                     <li key={index} className={`w-20 h-20 ${colorClass} rounded-full flex flex-col items-center justify-center m-2`}>
                        <p className="mb-2">{persona.nombre}</p>
                        <p className="text-4xl" >{persona.mensaje}</p>
                     </li>
                  )
               })}

               <li className="flex justify-center items-center" style={{ gridColumn: '2 / span 2' }}>
                  <div className={`w-40 h-40 rounded-full bg-fuchsia-400 text-l-black flex flex-col items-center justify-center`}>
                     <p>{personasUser}</p>
                     <p className="text-4xl">{emojis}</p>
                  </div>
               </li>

            </ul>
         </div>

         <div className='flex flex-col items-center justify-center shadow-md px-6 pb-2 mb-1 bg-cyan-400'>
            <div className='flex flex-wrap justify-center space-x-4 space-y-4'>

               <button onClick={handleEmoji} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 mt-4 ml-4 text-4xl'>
                  ❤️
               </button>

               <button onClick={handleEmoji1} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  👍
               </button>

               <button onClick={handleEmoji2} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  💪
               </button>

               <button onClick={handleEmoji3} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  😅
               </button>

               <button onClick={handleEmoji4} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  👊
               </button>

               <button onClick={handleEmoji5} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  💦
               </button>

               <button onClick={handleEmoji6} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  🐘
               </button>

               <button onClick={handleEmoji7} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  🏆
               </button>

               <button onClick={handleEmoji8} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  👏
               </button>

               <button onClick={handleEmoji9} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  🥵
               </button>

               <button onClick={handleRanking} className='bg-slate-200 hover:bg-slate-300 rounded py-3 px-6 text-xl mt-4'>
                  Ranking
               </button>

               <div>
                  <a href="https://www.paypal.me/trottingteam" target="_blank" rel="noopener noreferrer">
                     <img
                        src="https://trottingexperience.com/wp-content/uploads/2024/02/Boton-donar-paypal-300x166-.png"
                        className="w-48 h-auto object-cover rounded-md"
                        alt="Botón de donar a través de PayPal"
                     />
                  </a>
               </div>

               <button onClick={handleClase} className='bg-slate-200 hover:bg-slate-300 rounded py-3 px-6 text-xl mr-4'>
                  Cerrar Clase
               </button>

               <button onClick={handleLogout} className='bg-slate-200 hover:bg-slate-300 rounded py-3 px-6 text-xl mr-4'>
                  Logout
               </button>

            </div>

         </div >

      </div >
   );
}
