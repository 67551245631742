import React, { useState, useEffect } from 'react';

const Distancia = () => {
  const [distancia, setDistancia] = useState(0);

  useEffect(() => {
    // Simulando el aumento de distancia cada segundo
    const interval = setInterval(() => {
      // Incrementar la distancia en un valor arbitrario (puedes ajustarlo)
      setDistancia((prevDistancia) => prevDistancia + 0.001);
    }, 1000);

    // Limpieza del intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, []); // El efecto se ejecuta solo al montar el componente

  return (
    <div className="text-xl font-bold mt-2">
      <p>{distancia.toFixed(3)} Km</p>
    </div>
  );
};

export default Distancia;
