import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { auth } from '../firebase';
import { getFirestore, collection, addDoc, getDocs, updateDoc, doc, where, query, increment } from 'firebase/firestore';

export const db = getFirestore();

export const authContext = createContext();

export const useAuth = () => {
  const context = useContext(authContext)
  if (!context) throw new Error('Añade el auth provider a la routas en App_js')
  return context;
}

const UsuariosContext = createContext();

export const useUsuarios = () => {
  return useContext(UsuariosContext);
};

export const UsuariosProvider = ({ children }) => { }

export function AuthProvider({ children }) {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const signup = (email, password) =>
    createUserWithEmailAndPassword(auth, email, password);

  const login = (email, password) =>
    signInWithEmailAndPassword(auth, email, password);

  const EstadoLogout = async () => {
    try {

      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      //console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      //console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);

        // Actualiza el estado 'activo' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { activo: false });
        //console.log('Estado actualizado correctamente abcsd el ID123:', usuarioActualDoc.id);
        // Remover todos los usuarios guardados en localStorage
        localStorage.removeItem('personasActivas');
        localStorage.removeItem('gimnasio');
        localStorage.removeItem('nombre');
        localStorage.removeItem('datosObtenidos');

        // Notificar que todos los usuarios han sido eliminados
        //console.log('Todos los usuarios han sido eliminados de la persistencia en localStorage.');

        return usuarioActualDoc.data();
      } else {
        //console.log('No se encontró un usuario con el UID proporcionado:',usuarioActualDoc.id );
        localStorage.removeItem('personasActivas');
        localStorage.removeItem('gimnasio');
        localStorage.removeItem('nombre');
        localStorage.removeItem('datosObtenidos');

      }
    } catch (error) {
      console.error('Error al obtener y actualizar el usuario en Firebase:', error);
    }
  };


  const logout = () => {

    signOut(auth);
  }

  const emoji = async () => {
    try {
      // Recuperar el usuario actual del almacenamiento local u otro lugar donde lo hayas guardado por si has actualizado pag.
      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      //console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      //console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);
        // Actualiza el estado 'mensajes' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { mensajes: '❤️' });
        //console.log('Estado actualizado correctamente para el IDBAh:', usuarioActualDoc);

        // Espera 3 segundos antes de limpiar el emoji
        setTimeout(async () => {
          await updateDoc(usuarioActualDoc.ref, { mensajes: '' });
          //console.log('Emoji limpiado');
        }, 5000);
      } else {
        console.log('El usuario actual no fue encontrado en la lista de usuarios.', usuarioActualDoc);
      }
    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }
  };


  const emoji1 = async () => {
    try {
      // Recuperar el usuario actual del almacenamiento local u otro lugar donde lo hayas guardado por si has actualizado pag.
      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      // console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      //console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UIDD proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      if (usuarioActualDoc) {
        //  console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);
        // Actualiza el estado 'mensajes' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { mensajes: '👍' });
        // Espera 3 segundos antes de limpiar el emoji
        setTimeout(async () => {
          await updateDoc(usuarioActualDoc.ref, { mensajes: '' });
          //console.log('Emoji limpiado');
        }, 5000);
      } else {
        //console.log('El usuario actual no fue encontrado en la lista de usuarios.', usuarioActualDoc);
      }
    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }
  };

  const emoji2 = async () => {
    try {
      // Recuperar el usuario actual del almacenamiento local u otro lugar donde lo hayas guardado por si has actualizado pag.
      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      //console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      //console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);
        // Actualiza el estado 'activo' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { mensajes: '💪' });
        // console.log('Estado actualizado correctamente para el ID:', usuarioActual);
        // Espera 3 segundos antes de limpiar el emoji
        setTimeout(async () => {
          await updateDoc(usuarioActualDoc.ref, { mensajes: '' });
          //console.log('Emoji limpiado');
        }, 5000);
      } else {
        console.log('¡Algo salió mal!');
      }
    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }
  };

  const emoji3 = async () => {
    try {
      // Recuperar el usuario actual del almacenamiento local u otro lugar donde lo hayas guardado por si has actualizado pag.
      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      //console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      //console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);
      //console.log('Usuario Actual uidd:', usuarioActualDoc.data().uidd);
      //console.log('Usuario Actual uidd2:', usuarioActual);


      if (usuarioActualDoc) {
        //console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);
        // Actualiza el estado 'activo' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { mensajes: '😅' });
        // console.log('Estado actualizado correctamente para el ID:', usuarioActual);
        // Espera 3 segundos antes de limpiar el emoji
        setTimeout(async () => {
          await updateDoc(usuarioActualDoc.ref, { mensajes: '' });
          //console.log('Emoji limpiado');
        }, 5000);
      } else {
        console.log('¡Algo salió mal!');
      }
    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }

  };

  const emoji4 = async () => {
    try {

      // Recuperar el usuario actual del almacenamiento local u otro lugar donde lo hayas guardado por si has actualizado pag.
      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      //console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      //console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);
        // Actualiza el estado 'activo' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { mensajes: '👊' });


        // Espera 3 segundos antes de limpiar el emoji
        setTimeout(async () => {
          await updateDoc(usuarioActualDoc.ref, { mensajes: '' });
          //console.log('Emoji limpiado');
        }, 5000);
      } else {
        console.log('¡Algo salió mal!');
        // console.log('Usuario Actual uidd2:', usuarioActual);
      }

    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }
  };

  const emoji5 = async () => {
    try {

      // Recuperar el usuario actual del almacenamiento local u otro lugar donde lo hayas guardado por si has actualizado pag.
      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      //console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      //console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);
        // Actualiza el estado 'activo' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { mensajes: '💦' });
        //console.log('Estado actualizado correctamente para el ID:', usuarioActual);
         // Espera 3 segundos antes de limpiar el emoji
         setTimeout(async () => {
          await updateDoc(usuarioActualDoc.ref, { mensajes: '' });
          //console.log('Emoji limpiado');
        }, 5000);
      } else {
        console.log('¡Algo salió mal!');
      }
    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }
  };

  const emoji6 = async () => {
    try {

      // Recuperar el usuario actual del almacenamiento local u otro lugar donde lo hayas guardado por si has actualizado pag.
      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      //console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      //console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);
        // Actualiza el estado 'activo' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { mensajes: '🐘' });
        //console.log('Estado actualizado correctamente para el ID:', usuarioActual);
         // Espera 3 segundos antes de limpiar el emoji
         setTimeout(async () => {
          await updateDoc(usuarioActualDoc.ref, { mensajes: '' });
          //console.log('Emoji limpiado');
        }, 5000);
      } else {
        console.log('¡Algo salió mal!');
      }
    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }

  };

  const emoji7 = async () => {
    try {

      // Recuperar el usuario actual del almacenamiento local u otro lugar donde lo hayas guardado por si has actualizado pag.
      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      //console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      // console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);
        // Actualiza el estado 'activo' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { mensajes: '🏆' });
        // console.log('Estado actualizado correctamente para el ID:', usuarioActual);
         // Espera 3 segundos antes de limpiar el emoji
         setTimeout(async () => {
          await updateDoc(usuarioActualDoc.ref, { mensajes: '' });
          //console.log('Emoji limpiado');
        }, 5000);
      } else {
        console.log('¡Algo salió mal!');
      }

    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }
  };

  const emoji8 = async () => {
    try {

      // Recuperar el usuario actual del almacenamiento local u otro lugar donde lo hayas guardado por si has actualizado pag.
      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      //console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      // console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);
        // Actualiza el estado 'activo' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { mensajes: '👏' });
        //  console.log('Estado actualizado correctamente para el ID:', usuarioActual);
         // Espera 3 segundos antes de limpiar el emoji
         setTimeout(async () => {
          await updateDoc(usuarioActualDoc.ref, { mensajes: '' });
          //console.log('Emoji limpiado');
        }, 5000);
      } else {
        console.log('¡Algo salió mal!');
      }
    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }
  };

  const emoji9 = async () => {
    try {

      // Recuperar el usuario actual del almacenamiento local u otro lugar donde lo hayas guardado por si has actualizado pag.
      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      //console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      // console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);
        // Actualiza el estado 'activo' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { mensajes: '🥵' });
        // console.log('Estado actualizado correctamente para el ID:', usuarioActual);
         // Espera 3 segundos antes de limpiar el emoji
         setTimeout(async () => {
          await updateDoc(usuarioActualDoc.ref, { mensajes: '' });
          //console.log('Emoji limpiado');
        }, 5000);
      } else {
        console.log('¡Algo salió mal!');
      }
    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }
  };


  const cerrarClase = async (personasActivasEnGimnasio) => {
    try {
      const personasRef = collection(db, 'usuarios');

      for (let i = 0; i < personasActivasEnGimnasio.length; i++) {
        const personaUidd = personasActivasEnGimnasio[i].id;

        if (personaUidd) {
          const usuariosQuery = query(personasRef, where('uidd', '==', personaUidd));
          const usuariosSnapshotFiltered = await getDocs(usuariosQuery);

          if (!usuariosSnapshotFiltered.empty) {
            const usuarioDoc = usuariosSnapshotFiltered.docs[0];
            const personaDocRef = doc(personasRef, usuarioDoc.id);

            // Actualiza la propiedad activo a false en la base de datos
            await updateDoc(personaDocRef, { nombreGym: '' });
            console.log(`Se actualizó activo a false para el usuario con uidd: ${personaUidd}`);
          } else {
            console.log(`Usuario con uidd ${personaUidd} no encontrado en el snapshot`);
          }
        } else {
          //console.log(`El valor de uidd es undefined o null para la persona en la posición ${i}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };


  const listaUsuarios = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'usuarios'));
      const personas = [];
      querySnapshot.forEach((doc) => {
        // Accede a los datos reales utilizando el método data()
        const personaData = doc.data();
        personas.push(personaData);
      });

      // Muestra los datos en la consola o realiza cualquier otra operación
      //console.log('Datos de personas HEY:', personas);

      return personas;
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const listaClaves = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'claves'));
      const claves = [];

      querySnapshot.forEach((doc) => {
        // Accede a los datos reales utilizando el método data()
        const claveData = doc.data();
        claves.push(claveData);
      });

      // Muestra los datos en la consola o realiza cualquier otra operación
      return claves;
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };


  const Estado = async (uid) => {
    try {
      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === uid);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado:', usuarioActualDoc.data().uidd);
        localStorage.setItem('nombre', JSON.stringify(usuarioActualDoc.data().nombre));

        // Actualiza el estado 'activo' del documento con el ID correspondiente
        await updateDoc(usuarioActualDoc.ref, { activo: true });
        setUsuarioActual(usuarioActualDoc.data().uidd);

        // Recuperar los usuarios existentes del localStorage
        const usuariosActuales = JSON.parse(localStorage.getItem('usuariosActuales')) || [];

        // Verificar si el usuario ya existe en la lista
        if (!usuariosActuales.includes(usuarioActualDoc.data().uidd)) {
          // Agregar el nuevo usuario a la lista de usuarios solo si no existe
          usuariosActuales.push(usuarioActualDoc.data().uidd);

          // Almacenar la lista actualizada de usuarios en el localStorage
          localStorage.setItem('usuariosActuales', JSON.stringify(usuariosActuales));
        } else {
          //console.log('El usuario ya existe en localStorage.');
        }
        // Devuelve el usuario encontrado o realiza alguna otra acción necesaria
        return usuarioActualDoc.data();
      } else {
        //console.log('No se encontró un usuario con el UID proporcionado:', uid);
      }
    } catch (error) {
      console.error('Error al obtener y actualizar el usuario en Firebase:', error);
    }
  };

  const verificarUsuarioEnFirebase = async (uid) => {
    try {
      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === uid);

      if (usuarioActualDoc) {
        //console.log('Usuario encontradoXXX:', usuarioActualDoc.data().nombre);

        localStorage.setItem('nombre', JSON.stringify(usuarioActualDoc.data().nombre));

        return usuarioActualDoc.exists;
      } else {
        //console.log('No se encontró un usuario con el UID proporcionado:', uid);
        return false;

      }
    } catch (error) {
      console.error('Error al verificar usuario en Firebase', error);
      return false;
    }
  };

  const resetRanking = async () => {
    try {
      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);
  
      usuariosSnapshot.forEach(async (doc) => {
        try {
          await updateDoc(doc.ref, { puntos: 0 });
          //console.log(`Puntos reseteados a cero para el usuario con ID: ${doc.id}`);
        } catch (updateError) {
          //console.error(`Error al actualizar los puntos para el usuario con ID: ${doc.id}`, updateError);
        }
      });
      
      console.log('Todos los puntos han sido reseteados a cero.');
  
    } catch (error) {
      console.error('Error al obtener y actualizar los usuarios en Firebase:', error);
    }
  };
  



  const [usuarioActual, setUsuarioActual] = useState();

  const guardarEnFirebase = async (nombre, uidd) => {
    try {
      const nuevaInformacion = {
        nombre: nombre,
        activo: true,
        mensajes: '❤️',
        uidd: uidd,
        puntos: 0,
        ranking: 0,
      };

      const nuevoDocumentoRef = await addDoc(collection(db, 'usuarios'), nuevaInformacion);
      const idDocumento = nuevoDocumentoRef.id;
      //console.log('ID del nuevo documento:', idDocumento);

      const usuario = { id: idDocumento, nombre, ...nuevaInformacion };
      setUsuarioActual(usuario);


    } catch (error) {
      console.error('Error al guardar en Firebase:', error);
      throw error;
    }
  };


  const GuardarEnLocal = async (uid) => {
    try {

      // Recuperar los usuarios existentes del localStorage del uid que no existe por tanto lo crea
      const usuariosGuardados = JSON.parse(localStorage.getItem('uid')) || [];

      // Agregar el nuevo usuario y unico usuario en el login a la lista de usuarios
      usuariosGuardados.push(uid);

      // Almacenar la lista actualizada de usuarios en el localStorage machacando el ultimo usuario que haya y asi siempre tendre el usuario
      localStorage.setItem('usuario', JSON.stringify(usuariosGuardados));

    } catch (error) {
      console.error('Error al guardar en localStorage:', error);
    }
  };

  const [nombreGimnasio, setNombreGimnasio] = useState();

  const addInfoGym = async (nombreGimnasio) => {

    try {
      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      //console.log('Usuario Actual:', usuarioActualString);

      // Convertir la cadena a un objeto JavaScript
      const usuarioActual = JSON.parse(usuarioActualString);
      // console.log('Usuario Actual:', usuarioActual);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      // console.log('Usuario ActualDOC', usuarioActualDoc);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado Gym:', usuarioActualDoc.data().uidd);
        await updateDoc(usuarioActualDoc.ref, { nombreGym: nombreGimnasio });
        //console.log('Estado actualizado correctamente para el ID Gym:', usuarioActual);
        setNombreGimnasio(nombreGimnasio);
      } else {
        // console.log('¡Algo salió mal123!', usuarioActualDoc);
      }
    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }

  };

  const addRanking = async () => {

    try {

      const puntosGuardados = localStorage.getItem('Puntos');
      const puntosGuardadosNumero = parseInt(puntosGuardados - 1, 10); // Convierte la cadena a un número entero

      const usuarioActualString = localStorage.getItem('usuario'); // Por ejemplo, desde el almacenamiento local
      const usuarioActual = JSON.parse(usuarioActualString);

      const usuariosRef = collection(db, 'usuarios');
      const usuariosSnapshot = await getDocs(usuariosRef);

      // Encuentra el usuario correspondiente al UID proporcionado
      const usuarioActualDoc = usuariosSnapshot.docs.find(doc => doc.data().uidd === usuarioActual[0]);

      //console.log('Usuario ActualDOC', usuarioActualDoc);

      if (usuarioActualDoc) {
        //console.log('Usuario encontrado Gym:', usuarioActualDoc.data().uidd);
        await updateDoc(usuarioActualDoc.ref, { puntos: increment(puntosGuardadosNumero) });
        //console.log('Estado actualizado correctamente para el ID Gym:', usuarioActual);
      } else {
        // console.log('¡Algo salió mal123!', usuarioActualDoc);
      }
    } catch (error) {
      console.error('Error al actualizar el estado en Firebase:', error);
    }

  };

  const loginWithGoogle = () => {
    const googleProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleProvider);
  };

  const resetPassword = (email) => sendPasswordResetEmail(auth, email);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();

  }, []);

  return (
    <authContext.Provider value={{
      signup,
      login,
      user,
      logout,
      loading,
      emoji,
      emoji1,
      emoji2,
      emoji3,
      emoji4,
      emoji5,
      emoji6,
      emoji7,
      emoji8,
      emoji9,
      listaUsuarios,
      listaClaves,
      guardarEnFirebase,
      GuardarEnLocal,
      Estado,
      EstadoLogout,
      cerrarClase,
      verificarUsuarioEnFirebase,
      addInfoGym,
      addRanking,
      resetRanking,
      loginWithGoogle,
      resetPassword
    }}> {children}</authContext.Provider>
  );
}