import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './components/Home';
import { Login } from './components/Login';
import { Register } from './components/Register';
import Comprobacion from './components/Comprobacion';
import { HomeUser } from './components/HomeUser';
import Ranking from './components/Ranking';

import { AuthProvider } from './context/authContext';
import { ProtectedRoute } from './components/ProtectedRoute';

function App() {
  return (
    <div className='bg-slate-300 h-screen text-black flex'>
      <AuthProvider>
        <Routes>
          <Route path="/" element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          } />

          <Route path="/comprobacion" element={
            <ProtectedRoute>
              <Comprobacion />
            </ProtectedRoute>
          } />

          <Route path="/homeuser" element={
            <ProtectedRoute>
              <HomeUser />
            </ProtectedRoute>
          } />

          

          <Route path="/ranking" element={<Ranking />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

        </Routes>

      </AuthProvider>
    </div>
  );
}

export default App;
