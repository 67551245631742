// Import the functions you need from the SDKs you need
import { initializeApp, firebase } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from 'firebase/auth';
import {getDatabase} from 'firebase/database';
import {} from 'firebase/firestore';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDOWXTzIt1X9LVkzA6BagaOcN_Zusd6psQ",
  authDomain: "apptrotting.firebaseapp.com",
  projectId: "apptrotting",
  storageBucket: "apptrotting.appspot.com",
  messagingSenderId: "1091274939",
  appId: "1:1091274939:web:6a1ec8c7486132eb40c19e",
  measurementId: "G-D5JKN356G3",
  databaseURL: 'https://apptrotting-default-rtdb.europe-west1.firebasedatabase.app'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const rt = getDatabase(app);

export const saveEmoji = (emoji) =>{
  console.log(emoji);
}