import React, { useState, useEffect } from 'react';

const Reloj = () => {
  const [minutos, setMinutos] = useState(0);
  const [segundos, setSegundos] = useState(0);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setSegundos(prevSegundos => (prevSegundos === 59 ? 0 : prevSegundos + 1));
      setMinutos(prevMinutos => (segundos === 59 ? prevMinutos + 1 : prevMinutos));
    }, 1000);

    return () => {
      clearInterval(intervalo);
    };
  }, [segundos]);

  return (
    <div className="text-xl font-bold mt-2">
      <p>{String(minutos).padStart(2, '0')}:{String(segundos).padStart(2, '0')}</p>
    </div>
  );
};

export default Reloj;
