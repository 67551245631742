
import { useAuth } from '../context/authContext';
import { useState, useEffect } from 'react';
import Reloj from './Reloj.js';
import Calorias from './Calorias.js';
import Distancia from './Distancia.js';
import AccelerometerApp from './AccelerometerApp.js';
import { useNavigate } from 'react-router-dom';



export function HomeUser() {

   const { logout, loading, listaUsuarios, EstadoLogout, addInfoGym, addRanking } = useAuth();

   const navigate = useNavigate();

   const [showAd, setShowAd] = useState(true);

   useEffect(() => {
      const timer = setTimeout(() => {
         setShowAd(false);
      }, 5000); // 5000 milisegundos (5 segundos)

      return () => clearTimeout(timer);
   }, []); // Este efecto se ejecuta solo una vez al montar el componen

   const handleLogout = async () => {
      try {
         await EstadoLogout();
         await logout();

      } catch (error) {
         console.error(error);
      }
   };

   const handleRanking = async (puntosRanking) => {
      try {
         navigate('/Ranking')

      } catch (error) {
         console.error(error);
      }
   };


   const [emojis, setEmojis] = useState('');

   const handleEmoji = async () => {
      try {
         await setEmojis('❤️');
      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji1 = async () => {
      try {
         await setEmojis('👍');
      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji2 = async () => {
      try {
         await setEmojis('💪');
      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji3 = async () => {
      try {
         await setEmojis('😅');
      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji4 = async () => {
      try {
         await setEmojis('👊');
      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji5 = async () => {
      try {
         await setEmojis('💦');
      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji6 = async () => {
      try {
         await setEmojis('🐘');
      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji7 = async () => {
      try {
         await setEmojis('🏆');
      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji8 = async () => {
      try {
         await setEmojis('👏');
      } catch (error) {
         console.error(error);
      }
   };

   const handleEmoji9 = async () => {
      try {
         await setEmojis('🥵');
      } catch (error) {
         console.error(error);
      }
   };

   const handleClase = async () => {
      try {
         addInfoGym('73');
         localStorage.removeItem('datosObtenidos');
         navigate('/Comprobacion')


      } catch (error) {
         console.error(error);
      }
   };


   const [personas, setPersonas] = useState([]);
   const [nombreUserSinGym, setNombreUserSinGym] = useState('');


   const handlePersonas = async () => {
      try {
         let datosObtenidos = JSON.parse(localStorage.getItem('datosObtenidos'));

         // Si datosObtenidos está vacío, obtenemos los datos nuevamente
         if (!datosObtenidos) {
            datosObtenidos = await listaUsuarios();
            localStorage.setItem('datosObtenidos', JSON.stringify(datosObtenidos));
            //console.log('Ver cuantas veces entra aqui el homeuser');
         }

         const userSinGymString = localStorage.getItem('usuario');
         const userSinGym = JSON.parse(userSinGymString);

         const nombreUserSinGymString = localStorage.getItem('nombre');
         const nombreUserSinGym = JSON.parse(nombreUserSinGymString);
         setNombreUserSinGym(nombreUserSinGym);

         // Filtrar personas activas pedaleando sin gimnasio
         const personasActivasEnGimnasio = datosObtenidos
            .filter(persona => persona.activo && persona.nombreGym === '' && persona.uidd != userSinGym)
            .map(persona => ({ nombre: persona.nombre, mensaje: persona.mensajes || [] }));
         setPersonas(personasActivasEnGimnasio);
         return;

      } catch (error) {
         console.error(error);
      }
   };



   useEffect(() => {
      handlePersonas();
   }, []); // El array vacío asegura que se llame solo una vez al montar el componente


   if (loading) return <h1> Loading...</h1>

   return (

      <div className='w-full text-black bg-fuchsia-300'>
         <div className>
            <h1 className='text-xl'>
               <img
                  src="https://trottingexperience.com/wp-content/uploads/2023/08/Logo-dark-11.png"
                  className=" top left-0 w-160 h-160 object-cover rounded-md mx-auto my-8"
               />
               <p className="text-xl text-center font-bold">You'll Never Train Alone!</p>

            </h1>

            <div className="flex flex-wrap justify-center">
               <div className="m-4 text-center">
                  <Reloj />
                  <h1>Duración</h1>
               </div>

               <div className="m-4 text-center">
                  <Distancia />
                  <h1>Recorrido</h1>
               </div>

               <div className="m-4 text-center">
                  <Calorias />
                  <h1>Calorías</h1>
               </div>
            </div>

            <ul className='grid grid-cols-4 gap-2 text-xl justify-center'>
               {personas.map((persona, index) => {
                  const colors = {
                     0: 'bg-red-500',
                     1: 'bg-blue-500',
                     2: 'bg-green-500',
                     3: 'bg-yellow-500',
                     4: 'bg-blue-500',
                     5: 'bg-purple-500',
                     6: 'bg-blue-500',
                     7: 'bg-orange-500',
                     8: 'bg-teal-500',
                     9: 'bg-blue-300',
                     10: 'bg-yellow-200',
                     11: 'bg-cyan-500',
                     12: 'bg-lime-500',
                     13: 'bg-amber-500',
                  };
                  const colorClass = colors[index % 14];
                  return (
                     <li key={index} className={`w-20 h-20 ${colorClass} rounded-full flex flex-col items-center justify-center m-2`}>
                        <p className="mb-2">{persona.nombre}</p>
                        <p className="text-4xl" >{persona.mensaje}</p>
                     </li>
                  )
               })}

               <li className="flex justify-center items-center" style={{ gridColumn: '2 / span 2' }}>
                  <div className={`w-40 h-40 rounded-full bg-fuchsia-400 text-l-black flex flex-col items-center justify-center `}>                 <p className>{nombreUserSinGym}</p>
                     <p className="text-4xl">{emojis}</p>
                  </div>
               </li>
            </ul>
         </div>
         <div className='bg-fuchsia-300 flex flex-col items-center justify-center shadow-md px-6 pb-2 mb-1'>
            <div className='flex flex-wrap justify-center space-x-4 space-y-4'>

               <button onClick={handleEmoji} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 mt-4 ml-4 text-4xl'>
                  ❤️
               </button>

               <button onClick={handleEmoji1} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  👍
               </button>

               <button onClick={handleEmoji2} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  💪
               </button>

               <button onClick={handleEmoji3} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  😅
               </button>

               <button onClick={handleEmoji4} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  👊
               </button>

               <button onClick={handleEmoji5} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  💦
               </button>

               <button onClick={handleEmoji6} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  🐘
               </button>

               <button onClick={handleEmoji7} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  🏆
               </button>

               <button onClick={handleEmoji8} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  👏
               </button>

               <button onClick={handleEmoji9} className='bg-slate-200 hover:bg-slate-300 rounded py-6 px-6 text-4xl'>
                  🥵
               </button>

               <button onClick={handleRanking} className='bg-slate-200 hover:bg-slate-300 rounded py-3 px-6 text-xl mt-4'>
                  Ranking
               </button>

               <div>
                  <a href="https://www.paypal.me/trottingteam" target="_blank" rel="noopener noreferrer">
                     <img
                        src="https://trottingexperience.com/wp-content/uploads/2024/02/Boton-donar-paypal-300x166-.png"
                        className="w-48 h-auto object-cover rounded-md"
                        alt="Botón de donar a través de PayPal"
                     />
                  </a>
               </div>

               <button onClick={handleClase} className='bg-slate-200 hover:bg-slate-300 rounded py-3 px-6 text-xl mr-4'>
                  Cerrar Clase
               </button>

               <button onClick={handleLogout} className='bg-slate-200 hover:bg-slate-300 rounded py-3 px-6 text-xl mr-4'>
                  Logout
               </button>

            </div>

         </div >

      </div >


   );
}
