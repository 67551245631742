import { useState, useEffect } from 'react'
import { useAuth } from '../context/authContext'
import { Link, useNavigate } from 'react-router-dom';
import { Alert } from './Alert';

export function Register() {
    const { guardarEnFirebase, GuardarEnLocal, Estado } = useAuth();

    const [user, setUser] = useState({
        email: '',
        password: '',
    });

    const { signup } = useAuth()
    const navigate = useNavigate()
    const [error, setError] = useState();

    const handleChange = ({ target: { name, value } }) => {

        setUser({ ...user, [name]: value });

    }
    const [nombre, setNombre] = useState('');

    const handleNombre = async (e) => {
        const nuevoNombre = e.target.value;
        setNombre(nuevoNombre);
        localStorage.setItem('nombre', JSON.stringify(nuevoNombre));

    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signup(user.email, user.password);
            const uid = userCredential.user.uid;

            await guardarEnFirebase(nombre, uid);
            await GuardarEnLocal(uid);
            await Estado(uid);
            localStorage.setItem('Puntos', 0);
            navigate('/Comprobacion')

        } catch (error) {
            setError(error.message);
        }
    };


    return (
        <div className='w-full max-w-xs m-auto'>

            {error && <Alert message={error} />}

            <form onSubmit={handleSubmit} className='bg-white
                                            shadow-md rounded px-8 pt-6 pb-8 mb-4'>


                <label text="name" className='block text-gray-700 text-sm font-bold mb-2'>Nombre</label>
                <input type="name"
                    name="name"
                    placeholder="Nombre"
                    className='shadow appearance-none border rounded w-full py-2 py-3 text-gray-700 leading-tight
                    focus: outline-none focus:shadow-outline'
                    onChange={handleNombre} />

                <div className='mb-4'>
                    <label htmlFor="email" className='block 
                            text-gray-700 tx-sm font-bold my-2'>Email</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="tuemail@gmail.com"
                        onChange={handleChange}
                        className='shadow appearance-none border rounded w-full py-2 py-3 text-gray-700 leading-tight
            focus: outline-none focus:shadow-outline'

                    />

                </div>

                <div className='mb-4'>
                    <label htmlFor="password"
                        className='block 
            text-gray-700 tx-sm font-bold my-2'
                    >password</label>
                    <input
                        type="password" name="password" id="password"
                        onChange={handleChange}
                        placeholder='******'
                        className='shadow appearance-none border rounded w-full py-2 py-3 text-gray-700 leading-tight
            focus: outline-none focus:shadow-outline'
                    />

                </div>

                <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold text-sm py-2 px-4 rounded focus:outline-none
            focus:shadow-outline'> Regístrate </button>

            </form>

            <p className='my-4 text-sm flex justify-between px-3'> Ya tienes una cuenta? <Link to='/login'> Logueate </Link> </p>


        </div>

    )

}

export default Register;
