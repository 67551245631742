import React, { useState, useEffect } from 'react';

const Calorias = () => {
  const [calorias, setCalorias] = useState(0);

  useEffect(() => {
    // Simulando el aumento de calorías cada segundo
    const interval = setInterval(() => {
      setCalorias((prevCalorias) => prevCalorias + 0.01);
    }, 1000);

    // Limpieza del intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, []); // El efecto se ejecuta solo al montar el componente

  return (
    <div className="text-xl font-bold mt-2">
      <p> {calorias.toFixed(2)} Kcal</p>
    </div>
  );
};

export default Calorias;
