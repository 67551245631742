import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext'; // Asegúrate de importar la configuración de Firebase

const Comprobacion = () => {

  const { listaClaves, Estado, addInfoGym } = useAuth();
  const navigate = useNavigate();

  const [nombreGimnasio, setNombreGimnasio] = useState(''); // Nueva variable de estado
  const [mensajeError, setMensajeError] = useState('');

  useEffect(() => {
    obtenerNombresGimnasios(); // Llamada a la función que obtiene la lista de gimnasios al cargar el componente
    // (aquí podrías establecer el nombre del gimnasio actual)
  }, []);


  const handleVerificarCodigo = async () => {
    try {
      // Obtener la referencia a la colección secreto en Firestore
      const secretoRef = await listaClaves();

      const gimnasioEncontrado = secretoRef.find(
        (gym) => gym.nombre === nombreGimnasio && gym.codigo === codigoIngresado2
      );

      if (gimnasioEncontrado) {

        localStorage.setItem('gimnasio', JSON.stringify(nombreGimnasio));

        await addInfoGym(nombreGimnasio);
        await navigate('/');

      } else {
        setMensajeError('Código introducido incorrecto, pruebe de nuevo');

      }

    } catch (error) {
      console.error('Error al verificar el código:', error);
    }
  };


  const [nombresGimnasios, setNombresGimnasios] = useState([]);

  const obtenerNombresGimnasios = async () => {
    try {
      const gimnasios = await listaClaves();
      const nombres = gimnasios.map((gimnasio) => gimnasio.nombre);
      setNombresGimnasios(nombres);
    } catch (error) {
      console.error('Error al obtener los nombres de los gimnasios:', error);
    }
  };

  const [codigoIngresado2, setCodigoIngresado2] = useState('');
  const [codigoIngresado, setCodigoIngresado] = useState('');

  const handleCodigoChange = (nombre, value) => {
    setNombreGimnasio(nombre);
    setCodigoIngresado2(value);
    setCodigoIngresado((prevCodigo) => ({
      ...prevCodigo,
      [nombre]: value,
    }));
  };


  const handlePedalear = async () => {

    try {
      addInfoGym('');
      await Estado();
      localStorage.setItem('gimnasio', JSON.stringify(''))
      navigate('/homeuser')
    } catch (error) {
    }

  }

  return (

    <div className="w-full max-w-md mx-auto p-6 rounded-md shadow-md bg-teal-300">
      <h1 className="text-2xl font-semibold mb-4">Salas de Equipos</h1>
      <img
        src="https://trottingexperience.com/wp-content/uploads/2023/08/Logo-dark-11.png"
        className=" top left-0 w-160 h-160 object-cover rounded-md"
      />
      {mensajeError && <p style={{ color: 'red' }}>{mensajeError}</p>}

      {nombresGimnasios.map((nombre, index) => (
        <div key={index} className="mb-6">
          <p className="text-lg font-medium">{nombre}</p>
          <label className="block mt-2">
            <span className="text-gray-700">Código:</span>
            <input
              className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring focus:border-blue-300"
              type="text"
              value={codigoIngresado[nombre] || ''}
              onChange={(e) => handleCodigoChange(nombre, e.target.value)}
            />
          </label>
          <button
            className="mt-2 mb-6 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
            onClick={() => handleVerificarCodigo(nombre)}
          >
            Verificar Código
          </button>

          <div className="relative">
            <img
              src="https://trottingexperience.com/wp-content/uploads/2023/12/intermediate.png"
              className=" top left-0 w-160 h-160 object-cover rounded-md"
            />
            <button
              className="mt-2 mb-8 bg-blue-500 text-white text-lg px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 relative z-10"
              onClick={handlePedalear}
            >
              Solo Pedalear
            </button>
            <div>
              <a href="https://www.paypal.me/trottingteam" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://trottingexperience.com/wp-content/uploads/2024/02/Boton-donar-paypal-300x166-.png"
                  className="top left-0 w-160 h-160 object-cover rounded-md"
                  alt="Botón de donar a través de PayPal"
                />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>

  );
};

export default Comprobacion;

