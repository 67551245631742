import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth, db } from '../context/authContext'; // Asegúrate de importar la configuración de Firebase

const Ranking = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [mesActual, setMesActual] = useState('');
    const navigate = useNavigate();

    const { logout, EstadoLogout, addInfoGym } = useAuth();


    const handleLogout = async () => {
        try {
            await addInfoGym('');
            localStorage.removeItem('gimnasio');
            await EstadoLogout();
            await logout();

        } catch (error) {
            console.error(error);
        }
    };


    const handleClase = async () => {
        try {
            localStorage.removeItem('gimnasio');
            navigate('/Comprobacion')


        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const usuariosRef = collection(db, 'usuarios');
                const q = query(usuariosRef, orderBy('puntos', 'desc'));
                const usuariosSnapshot = await getDocs(q);

                const usuariosData = usuariosSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setUsuarios(usuariosData);
            } catch (error) {
                console.error('Error al obtener los usuarios:', error);
            }
        };

        fetchUsuarios();

        // Obtener el mes actual
        const fecha = new Date();
        const opcionesFecha = { month: 'long' };
        const nombreMes = new Intl.DateTimeFormat('es-ES', opcionesFecha).format(fecha);
        const primeraLetraMayuscula = nombreMes.charAt(0).toUpperCase() + nombreMes.slice(1);
        setMesActual(primeraLetraMayuscula);

    }, []);

    return (
        <div className='w-full text-black bg-amber-200'>
            <div>
                <h1 className='text-xl mb-4'>
                    <img
                        src="https://trottingexperience.com/wp-content/uploads/2023/08/Logo-dark-11.png"
                        className=" top left-0 w-160 h-160 object-cover rounded-md mx-auto my-8 mb-1"
                    />
                </h1>
                <div className="p-4 bg-amber-200">
                    <h2 className="text-4xl font-bold mb-2 text-center">Ranking de Usuarios</h2>
                    <p className="text-center text-3xl mb-2">{mesActual}</p>
                    <div className="grid grid-cols-1 gap-4">
                        {usuarios.map((usuario, index) => (
                            <div key={usuario.id} className="p-4 bg-gray-100 rounded-lg flex items-center justify-center">
                                {index === 0 ? (
                                    <span className="mr-2" role="img" aria-label="trophy">🏆 🥇</span>
                                ) : index === 1 ? (
                                    <span className="mr-2" role="img" aria-label="second">🥈</span>
                                ) : index === 2 ? (
                                    <span className="mr-2" role="img" aria-label="third">🥉</span>
                                ) : null}
                                <span className="font-semibold">{index + 1}&nbsp;</span> {/* Espacio añadido después del número */}
                                <span>{usuario.nombre}: {usuario.puntos} points</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center space-y-4 bg-amber-200">
                    
                    <div>
                        <a href="https://www.paypal.me/trottingteam" target="_blank" rel="noopener noreferrer">
                            <img
                                src="https://trottingexperience.com/wp-content/uploads/2024/02/Boton-donar-paypal-300x166-.png"
                                className="w-48 h-auto object-cover rounded-md bg-amber-200"
                                alt="Botón de donar a través de PayPal"
                            />
                        </a>
                    </div>
                    <div>
                        <button onClick={handleClase} className='bg-slate-200 hover:bg-slate-300 rounded py-3 px-6 text-xl mr-4'>
                            Cerrar Ranking
                        </button>
                        <button onClick={handleLogout} className='bg-slate-200 hover:bg-slate-300 rounded py-3 px-6 text-xl mr-4'>
                            Logout
                        </button>
                    </div>
                </div>

            </div>
        </div>
        
    );
};

export default Ranking;
